<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem :to="`?action=new_pallet`" color="success">
            <TMessage content="New pallet" bold alignment="center">
              <template #prefix>
                <CIcon name="cil-plus" class="mr-2" />
              </template>
            </TMessage>
          </CListGroupItem>
          <CListGroupItem>
            <FilterForPallet :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(pallet, index) in pallets"
            :key="`${index}${pallet.id}`"
            :color="pallet_id_selected == pallet.id ? 'primary' : 'secondary'"
          >
            <SCardPallet
              class="cursor-pointer"
              @click.native="
                lodash.appendRouteQuery($router, {
                  query: { ...$route.query, pallet_id: pallet.id },
                })
              "
              :pallet="pallet"
              showLocation
            />
          </CListGroupItem>
          <CListGroupItem v-if="!pallets.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.pallets" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow>
        <CCol col="12" v-if="pallet_id_selected">
          <CCard>
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <TMessage content="Pallet" bold color="muted" noTranslate>
                <template #suffix>
                  <span class="ml-1">{{ pallet_id_selected }}</span>
                </template>
              </TMessage>
              <SMessagePalletLocation
                v-if="pallet_selected.location"
                color="info"
                class="ml-1"
                :location="pallet_selected.location"
              />
              <div class="ml-auto">
                <TButton
                  v-if="!boxes_in_pallet.length && pallet_selected.location_id"
                  icon="cil-trash"
                  color="danger"
                  content="Remove location"
                  class="mr-2"
                  @click="removePalletLocation"
                />
                <TButton
                  icon="cis-border-all"
                  variant="outline"
                  content="Set pallet location"
                  @click="showSetLocation = true"
                />
              </div>
            </CCardHeader>
            <CCardBody>
              <div v-if="pallet_selected.type">
                <TMessage content="Volume" :addClasses="['d-flex flex-wrap']">
                  <template #suffix>
                    (m3):
                    <TMessageVolume
                      class="ml-1"
                      :value="pallet_selected.boxes_volume"
                      suffix=""
                    />
                    <span class="mx-1">/</span>
                    <TMessageVolume
                      :value="pallet_selected.type.max_volume"
                      suffix=""
                    />
                  </template>
                </TMessage>
                <CProgress
                  :value="pallet_selected.boxes_volume"
                  :max="pallet_selected.type.max_volume"
                  style="height: 5px"
                />
                <TMessage
                  content="Weight"
                  class="mt-2"
                  :addClasses="['d-flex flex-wrap']"
                >
                  <template #suffix>
                    (kg):
                    <TMessageNumber
                      class="ml-1"
                      :value="pallet_selected.boxes_weight"
                    />
                    <span class="mx-1">/</span>
                    <TMessageNumber :value="pallet_selected.type.max_weight" />
                  </template>
                </TMessage>
                <CProgress
                  :value="pallet_selected.boxes_weight"
                  :max="pallet_selected.type.max_weight"
                  style="height: 5px"
                />
                <SModalSetPalletLocation
                  :show.sync="showSetLocation"
                  :pallet="pallet_selected"
                  @updated="$store.dispatch('warehouse.pallets.fetch')"
                />
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol col="12">
          <CCard>
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <div class="d-flex align-items-center">
                <TMessage
                  :content="box_id_selected ? 'Box' : 'Suggestion SKUs by SFA'"
                  bold
                  color="muted"
                />
                <TSpinner :loading="boxes_available_in_pallet_loading" />
              </div>
              <TButtonRefresh
                class="ml-auto"
                @click="fetchBoxesAvailableInPallet"
                :options="{
                  disabled: boxes_available_in_pallet_loading,
                }"
              />
            </CCardHeader>
            <CCardBody>
              <div class="scroll-x">
                <TMessageNoData
                  content="No SKU"
                  v-if="!boxes_available_in_pallet.length"
                />
                <div class="d-inline-flex" v-else>
                  <SCardSku
                    v-for="box in boxes_available_in_pallet"
                    :key="box.id"
                    :box="box"
                    class="my-3 mx-3"
                    :focusQuantity="box.duplicate - box.quantity_in_pallets"
                    focusQuantityColor="secondary"
                    focusQuantityDescription="Available quantity"
                    mainQuantityDescription="Total number of boxes"
                  />
                  <SButtonLoadmore
                    class="align-items-center mr-2"
                    store="warehouse.boxes_available_in_pallet"
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol col="12">
          <CCard v-if="pallet_id_selected">
            <CCardHeader class="d-flex flex-wrap align-items-center">
              <div class="d-flex align-items-center">
                <TMessage content="SKUs in pallet" bold>
                  <template #suffix>
                    <span class="ml-1">{{ pallet_id_selected || "..." }}</span>
                  </template>
                </TMessage>
                <TSpinner :loading="detail_loading" />
              </div>
            </CCardHeader>
            <CCardBody>
              <SInputSku @enter="enterSku" />
              <TMessageNoData content="No SKU" v-if="!boxes_in_pallet.length" />
              <CRow v-else class="d-flex align-items-stretch">
                <SCardSku
                  v-for="box_in_pallet in boxes_in_pallet"
                  :key="box_in_pallet.id"
                  :box="box_in_pallet.box"
                  class="my-3 mx-3"
                  :focus-quantity="box_in_pallet.current_quantity"
                  :focus-quantity-ediable="true"
                  @focus-quantity:change="
                    updateQuantityInPallet(box_in_pallet, $event)
                  "
                  :removable="true"
                  @remove="removeSku(box_in_pallet, $event)"
                  refresh
                  focusQuantityDescription="Quantity in pallets"
                  mainQuantityDescription="Total number of boxes"
                />
              </CRow>
              <SButtonLoadmore store="warehouse.pallet_boxes" />
            </CCardBody>
          </CCard>
          <CRow v-else class="d-flex">
            <SButtonNewPallet
              v-for="type in pallet_types"
              :key="type.id"
              :type="type"
              @created="selectPallet"
            />
          </CRow>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import filterable from "../../mixins/filterable";
import label from "../../mixins/label";
import audio from "../../mixins/audio";
import barcode from "../../mixins/barcode";
import FilterForPallet from "../../components/FilterForPallet.vue";

export default {
  components: { FilterForPallet },
  mixins: [filterable, label, barcode, audio],
  data() {
    return {
      showSetLocation: false,
    };
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.pallets.fetch.if-first-time");
    } else this.$store.dispatch("warehouse.pallets.apply-query", this.query);
  },
  computed: {
    pallet_id_selected() {
      return this.$route.query.pallet_id;
    },
    sfa_id_selected() {
      return this.$route.query.sfa_id;
    },
    box_id_selected() {
      return this.$route.query.box_id;
    },
    pallet_selected() {
      return this.$store.getters["warehouse.pallets.detail"];
    },
    pallet_types() {
      return this.$store.getters["warehouse.pallet_types.list"];
    },
    pallets() {
      return this.$store.getters["warehouse.pallets.list"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.pallets.fetching"];
    },
    detail_loading() {
      return this.$store.getters["warehouse.pallet_boxes.loading"];
    },
    boxes_in_pallet() {
      return this.$store.getters["warehouse.pallet_boxes.list"].filter(
        (p) => p.box
      );
    },
    boxes_available_in_pallet() {
      return this.$store.getters["warehouse.boxes_available_in_pallet.list"];
    },
    boxes_available_in_pallet_loading() {
      return this.$store.getters["warehouse.boxes_available_in_pallet.loading"];
    },
  },
  watch: {
    pallet_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("warehouse.pallets.detail.fetch", id);
          this.fetchBoxesInPallet();
        }
      },
    },
    sfa_id_selected: {
      immediate: true,
      handler() {
        this.fetchBoxesAvailableInPallet();
      },
    },
    box_id_selected: {
      immediate: false,
      handler() {
        this.fetchBoxesAvailableInPallet();
      },
    },
  },
  methods: {
    selectPallet(pallet) {
      this.$router.push({
        query: { ...this.$route.query, pallet_id: pallet.id },
      });
      const data = {
        ...this.labelMessage,
        type: "PALLET",
        palletId: pallet.id,
        quantity: 2,
        pageSize: "1",
      };
      this.$store.dispatch("printer.send-message", JSON.stringify(data));
    },
    enterSku(sku) {
      if (!sku && this.detail_loading) return;
      this.$store
        .dispatch("warehouse.pallet_boxes.create", {
          "boxes[0][id]": sku,
          pallet_id: this.pallet_id_selected,
          "boxes[0][quantity]": 1,
        })
        .then(() => {
          this.playSuccess();
          this.fetchBoxesInPallet();
          this.fetchBoxesAvailableInPallet();
          this.fetchPallet();
        })
        .catch(() => {
          this.playFail();
        });
    },
    fetchBoxesAvailableInPallet() {
      this.$store.dispatch("warehouse.boxes_available_in_pallet.apply-query", {
        "filter[sfa_id]": this.sfa_id_selected,
        "filter[id]": this.box_id_selected,
      });
    },
    fetchBoxesInPallet() {
      this.$store.dispatch("warehouse.pallet_boxes.apply-query", {
        "filter[pallet_id]": this.pallet_id_selected,
      });
    },
    fetchPallet() {
      this.$store.dispatch("warehouse.pallets.detail.refresh");
    },
    removeSku(pivot_pallet) {
      this.$store
        .dispatch("warehouse.pallet_boxes.delete", pivot_pallet.id)
        .then(() => {
          this.fetchBoxesInPallet();
          this.fetchBoxesAvailableInPallet();
        });
    },
    updateQuantityInPallet(pivot_pallet, quantity) {
      this.$store
        .dispatch("warehouse.pallet_boxes.update", {
          id: pivot_pallet.id,
          attributes: { quantity },
        })
        .then(() => {
          this.fetchBoxesInPallet();
          this.fetchBoxesAvailableInPallet();
        });
    },
    removePalletLocation() {
      this.$store
        .dispatch("warehouse.pallets.detail.update", {
          location_id: null,
        })
        .then(() => {
          this.fetchPallet();
        });
    },
    pickBarcode(e) {
      const code = this.press(e);
      if (code) {
        this.enterSku(code);
      }
    },
  },
};
</script>
